<template>
  <div class="box">
    <van-tabs v-model:active="active">
      <van-tab title="新用户完善信息"><binding :name="active" /> </van-tab>
      <van-tab title="老用户绑定账号"><binding :name="active" /> </van-tab>
    </van-tabs>
  </div>
</template>

<script>
import binding from './components/binding.vue'
export default {
  components: {
    binding
  },
  data() {
    return {
      active: 0
    }
  },
  methods: {}
}
</script>
<style scoped lang="less">
.box {
  min-height: 100%;
  padding: 0px 34px;
  background: white;
}
</style>
