<template>
  <van-form class="form" @submit="yzmTest">
    <van-field
      class="setBorder"
      v-if="name == 0"
      v-model="form.wechat"
      :border="false"
      placeholder="请正确填写您的微信ID，方便确认打款信息"
      :rules="[
        {
          required: true,
          message: '微信号不合法'
        }
      ]"
    />
    <van-field
      v-if="name == 0"
      class="setBorder"
      v-model="form.name"
      :border="false"
      placeholder="请填写您的姓名"
      :rules="[
        {
          required: true,
          message: '请填写您的姓名'
        }
      ]"
    />
    <!-- 输入手机号，调起手机号键盘 -->
    <van-field
      class="setBorder"
      v-model="form.phone"
      type="tel"
      clearable
      :border="false"
      placeholder="请填写手机号"
      :rules="[
        {
          required: true,
          validator: phoneTest,
          message: '请填写合法手机号'
        }
      ]"
    />
    <div class="yzm-box">
      <van-field
        class="setBorder"
        v-model="form.verificationCode"
        type="digit"
        :border="false"
        maxlength="6"
        placeholder="请输入短信验证码"
        :rules="[
          {
            required: true,
            message: '请填写短信验证码'
          }
        ]"
      />
      <van-button class="yzm-btn" :disabled="!yzmBtnFlag" @click="sendYzm">{{
        yzmText
      }}</van-button>
    </div>
    <van-field
      class="setBorder"
      :border="false"
      type="digit"
      v-if="name == 0"
      :readonly="readonlyFlag"
      v-model="form.invitationCode"
      placeholder="请输入顾问邀请码"
      :rules="[
        {
          required: true,
          message: '请输入顾问邀请码'
        }
      ]"
    >
      <template #button>
        <span
          @click="$router.push('/contact')"
          style="color: #fe774c; font-weight: bold"
          >获取顾问邀请码</span
        >
      </template>
    </van-field>
    <van-button class="submit" native-type="submit"> 提交 </van-button>
  </van-form>
</template>

<script>
import { Toast } from 'vant'

import {
  newBindingPhone,
  bindingPhone,
  sendVerificationCode
} from '@/api/login.js'
export default {
  props: {
    name: {
      type: Number,
      default: 0
    }
  },
  data() {
    return {
      yzmText: '获取验证码',
      yzmBtnFlag: true,
      readonlyFlag: false,
      form: {
        name: '',
        phone: '',
        verificationCode: '',
        invitationCode: ''
      }
    }
  },
  mounted() {
    /*  form.invitationCode = route.query.code
    if (route.query.code) {
      readonlyFlag.value = true
    } */
  },
  methods: {
    // 校验函数返回 true 表示校验通过，false 表示不通过
    phoneTest(val) {
      return /^1?[0-9]{10}$/.test(val)
    },
    async yzmTest(val) {
      // 0是新用户，1是老用户
      let data = null
      if (this.name == 0) {
        data = await newBindingPhone(this.form)
      } else {
        data = await bindingPhone(this.form)
      }
      if (data) {
        this.$store.dispatch('setToken', data)
        const toast = Toast.loading({
          duration: 0,
          forbidClick: true,
          message: '绑定成功!'
        })
        setTimeout(() => {
          toast.clear()
          this.$router.push('/extract')
        }, 2000)
      }
    },
    async sendYzm() {
      if (!this.phoneTest(this.form.phone)) {
        return Toast('手机号不合法')
      }
      await sendVerificationCode({
        phone: this.form.phone,
        sendChannel: this.name == 0 ? 'reg' : 'login'
      })
      Toast('发送成功')
      this.yzmText = 60
      this.yzmBtnFlag = false
      let st1 = setInterval(() => {
        this.yzmText--
      }, 1000)
      setTimeout(() => {
        window.clearInterval(st1)
        this.yzmText = '获取验证码'
        this.yzmBtnFlag = true
      }, 59000)
    }
  }
}
</script>
<style scoped lang="less">
.form {
  width: 100%;
  margin-top: 30px;
  .setBorder {
    padding: 0px 0px 36px;
  }
  .yzm-box {
    display: flex;
    justify-content: space-between;
    align-items: top;
    .yzm-btn {
      background: #fe774c;
      width: 310px;
      height: 80px;
      border-radius: 10px;
      color: white;
      margin-left: 36px;
      margin-top: -3px;
      letter-spacing: 2px;
      padding: 0px 20px;
    }
  }
}
.submit {
  width: 100%;
  background: #fe774c;
  border-radius: 10px;
  color: white;
  letter-spacing: 4px;
  margin-top: 35px;
  font-size: 34px;
}
/deep/ .van-field__body {
  border: 2px solid #d6d6d6 !important;
  padding: 12px 24px;
  border-radius: 10px;
}
</style>
